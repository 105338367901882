/* ============================= */
/*        BR Agro Styles         */
/* ============================= */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
/* Root Variables for Colors */
:root {
  --color-primary: #1DA43B;    /* Green for headings, buttons */
  --color-secondary: #E6F4E9;  /* Light greenish background */
  --color-text: #222222;       /* Dark text for readability */
  --color-accent: #007F2D;     /* Darker green for contrast */
  --color-bg-light: #F5F5F5;   /* Light background for sections */
  --color-border: #CCCCCC;     /* Subtle gray for borders */
}

/* ============================= */
/*       Global Styles           */
/* ============================= */

body {
  font-family: 'Roboto', sans-serif; /* Alternative: 'Lato' or 'Montserrat' */
  font-size: 16px;
  line-height: 1.6;
  color: var(--color-text);
  background-color: var(--color-bg-light);
  margin: 0;
  padding: 0;
}

/* ============================= */
/*       Typography              */
/* ============================= */

h1, h2, h3, h4, h5, h6 {
  color: var(--color-primary);
  font-weight: 700;
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

/* ============================= */
/*       Buttons                 */
/* ============================= */

button, .btn {
  background-color: var(--color-primary);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover, .btn:hover {
  background-color: var(--color-accent);
}

/* ============================= */
/*       Layout and Sections     */
/* ============================= */

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  padding: 50px 0;
  background: var(--color-secondary);
  border-radius: 10px;
  margin-bottom: 30px;
}

/* ============================= */
/*       Links                   */
/* ============================= */

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--color-accent);
}


/* ============================= */
/*          Navbar Fijo          */
/* ============================= */
.navbar-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: transparent;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Navbar con fondo blanco en móviles y tablets */
@media (max-width: 1024px) {
  .navbar-container {
    background: var(--color-bg-light);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* Navbar con fondo blanco al hacer scroll */
.navbar-container.scrolled {
  background: var(--color-bg-light);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* ============================= */
/*         Estilos Navbar        */
/* ============================= */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 5%;
  transition: height 0.3s ease-in-out;
}

/* Logo */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.05);
}

/* ============================= */
/*        Estilos del Menú       */
/* ============================= */
.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin-right: 25px;
}

.nav-link {
  color: #002B45;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: var(--color-primary);
}

/* ============================= */
/*        Estilos Menú Móvil     */
/* ============================= */

/* Botón Hamburguesa con Íconos */
.hamburger {
  display: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1100;
}

/* Mostrar en móviles */
@media (max-width: 1024px) {
  .hamburger {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: var(--color-primary);
  }
}

/* ============================= */
/*      Menú Móvil Animado       */
/* ============================= */
@media (max-width: 1024px) {
  .menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 50%;
    width: 80%;  /* Máximo 80% del ancho */
    max-height: 0;  /* Oculto inicialmente */
    background-color: white;
    padding: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    border-radius: 10px; /* Bordes redondeados */
    transform: translateX(-50%);
    transition: max-height 0.4s ease-in-out; /* Animación */
  }

  .menu.menu-open {
    max-height: 250px; /* Altura del menú desplegado */
    padding: 15px 0;
  }

  .menu li {
    text-align: center;
    font-size: 18px;
    padding: 12px 0;
    width: 100%;
    position: relative;
  }

  /* Separadores entre elementos del menú */
  .menu li:not(:last-child)::after {
    content: "";
    display: block;
    width: 70%;
    height: 1px;
    background-color: rgba(0, 43, 69, 0.2);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* ============================= */
/*       Overlay en Móvil        */
/* ============================= */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
}

.overlay.overlay-active {
  display: block;
  opacity: 1;
}


/* ============================= */
/*          Hero Section         */
/* ============================= */

.hero {
  position: relative;
  width: 100%;
  min-height: 80vh; /* Usamos min-height en lugar de height para evitar cortes */
  background: url("../public/media/HeroSectionBG.png") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 5% 5%;
  
  /* Espacio para el navbar fijo */
  padding-top: 80px;
  box-sizing: border-box;
  overflow: hidden; /* Asegura que nada salga del área */
}

/* Capa de superposición para mejorar la legibilidad del texto */
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
  z-index: 1;
}

/* Contenedor del contenido */
.hero-content {
  padding: 20px;
  position: relative;
  z-index: 2;
  max-width: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Titulares */
.hero-content h1 {
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 4px;
  word-wrap: break-word; /* Evita desbordamientos */
}

.hero-content h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  word-wrap: break-word;
}

/* Párrafos */
.hero-content p {
  color: #002B45;
  font-size: 18px;
  line-height: 1.5;
  max-width: 750px;
  margin: 0 auto 30px;
  word-wrap: break-word;
}

/* Resaltado de Texto */
.highlight {
  font-weight: 700;
  color: #002B45;
  border-radius: 5px;
}

.green-text {
  color: #1da43b;
}

.bold-text {
  font-weight: bold;
  color: #1da43b;
}

/* Botón CTA */
.btn-cta {
  display: inline-block;
  padding: 14px 28px;
  font-size: 18px;
  font-weight: 600;
  background: #1da43b;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.btn-cta:hover {
  transform: scale(1.05);
  background: #007f2d;
}

/* ============================= */
/*       Responsive Design       */
/* ============================= */

@media (max-width: 1024px) {
  .hero {
    min-height: 70vh; /* Asegura que se vea completo en tablets */
    padding-top: 40px;
  }

  .hero-content {
    max-width: 90%;
  }

  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content h2 {
    font-size: 24px;
  }

  .hero-content p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .hero {
    min-height: 70vh;
    padding-top: 40px;
  }

  .hero-content h1 {
    font-size: 32px;
  }

  .hero-content h2 {
    font-size: 20px;
  }

  .hero-content p {
    font-size: 14px;
  }

  .btn-cta {
    padding: 12px 24px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .hero {
    height: auto;
    padding-top: 40px;
  }

  .hero-content h1 {
    font-size: 28px;
  }

  .hero-content h2 {
    font-size: 18px;
  }

  .hero-content p {
    font-size: 14px;
  }

  .btn-cta {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* ============================= */
/*      Sección de Íconos        */
/* ============================= */

.company-highlights {
  width: 100%;
  background: var(--color-bg-light);
  padding: 40px 0;
  display: flex;
  justify-content: center;
}

/* Contenedor de íconos */
.company-highlights-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px; /* Menos separación para evitar descuadres */
  flex-wrap: nowrap; /* Mantener en una fila */
  max-width: 100%;
  width: 90%;
}

/* Cada elemento */
.company-highlight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: var(--color-text);
  font-weight: 600;
  white-space: normal; /* Permite saltos de línea si es necesario */
}

/* Íconos */
.company-highlight-icon {
  font-size: 22px;
  color: var(--color-primary);
}

/* ============================= */
/*       Responsive Design       */
/* ============================= */

@media (max-width: 1024px) {
  .company-highlights-container {
    flex-wrap: wrap; /* Permitir que se acomoden en varias filas */
    justify-content: center;
    gap: 25px;
  }
}

@media (max-width: 768px) {
  .company-highlight {
    font-size: 14px;
  }

  .company-highlight-icon {
    font-size: 18px;
  }
}


/* MAIN SECTION 2 */
.background2 {
  background: url(../public/media/main-2-bg.png) center/cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 05%;
}

.section-title h2 {
  font-size: 36px;
  color: #179847;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.main-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 50px;
}

.main-2-txt {
  max-width: 600px;
  color: #002B45;
}

.main-2-txt h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
}

.main-2-txt p {
  font-size: 20px;
  line-height: 1.6;
  color: #444;
  margin-bottom: 25px;
}

.btn-container {
  display: flex;
  justify-content: left;

}

.btn-primary{
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.btn-primary {
  background: #179847;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background:  #12c22f;
  transform: scale(1.05);
}


.main-2-img {
  max-width: 500px;
}

.main-2-img img {
  width: 100%;

}

/* Responsive Design */
@media (max-width: 1024px) {
  .main-2 {
      flex-direction: column;
      text-align: center;
      gap: 30px;
  }

  .main-2-txt {
      max-width: 100%;
  }

  .main-2-img {
      max-width: 80%;
  }

  .btn-container {
      flex-direction: column;
      align-items: center;
  }

  .btn-container .btn-primary,
  .btn-container .btn-secondary {
      width: 80%;
      font-size: 16px;
  }
}

@media (max-width: 768px) {
  .section-title h2 {
      font-size: 28px;
  }

  .main-2-txt h2 {
      font-size: 26px;
  }

  .main-2-txt p {
      font-size: 18px;
  }
}

@media (max-width: 480px) {
  .section-title h2 {
      font-size: 24px;
  }

  .main-2-txt h2 {
      font-size: 22px;
  }

  .main-2-txt p {
      font-size: 16px;
  }

  .btn-container .btn-primary,
  .btn-container .btn-secondary {
      width: 50%;
      font-size: 14px;
  }
}


/*MAIN SECTION 3*/

.main-section {
  height: auto;
  padding: 0 5%;
}

.section-title {
  text-align: center;
  padding: 10px;
  font-size: 36px;
  color: #179847;
}

.section-title2 {
  text-align: center;
  padding: 10px;
  font-size: 28px;
  color: #002B45;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.card-wrapper {
  display: flex;
  justify-content: center;
}

/* Media queries for responsiveness */

/* For large screens */
@media (min-width: 1200px) {
  .section-title, .section-title2 {
    font-size: 32px;
  }

  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
}

/* For tablets */
@media (max-width: 768px) {
  .section-title, .section-title2 {
    font-size: 24px;
  }

  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .section-title, .section-title2 {
    font-size: 20px;

  }

  .cards-container {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 5%;
  }
}


/*NOSOTROS*/
.about-page {
  width: 100%;
}

/* ============================= */
/*       Sección de Introducción */
/* ============================= */
.about-intro {
  position: relative;
  background: url("../public/media/About-bg.png") bottom/cover no-repeat;
  color: #002B45;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 10%;

}

/* Overlay para efecto blur */
.about-intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Oscurecer un poco */
  backdrop-filter: blur(6px); /* Efecto de desenfoque */
  z-index: 1;
}

/* Contenedor principal */
.about-intro-container {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;
}

/* Texto a la izquierda */
.about-intro-text {
  width: 50%;
  text-align: left;
  padding-right: 40px;
}

/* Mapa de México */
.about-intro-map {
  width: 50%;
  display: flex;
  justify-content: center;
}

.about-intro-map img {
  width: 100%;
  max-width: 450px;
}

/* ============================= */
/*        Biografía del Fundador */
/* ============================= */
.founder-section {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 60px 10%;
}

.founder-image img {
  width: 100%;
  max-width: 100%;

}

.founder-text {
  text-align: left;
}

/* ============================= */
/*        Historia de la empresa */
/* ============================= */
.company-history {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 60px 10%;
  gap: 40px;
}

.history-image img {
  width: 100%;
  max-width: 100%;
}

.history-text {
  text-align: left;
}

/* ============================= */
/*        Expansión Nacional */
/* ============================= */
.expansion-strategy {
  padding: 60px 10%;
  background: var(--color-bg-light);
  text-align: left;
}

/* ============================= */
/*       Compromiso con el campo */
/* ============================= */
.commitment-section {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 60px 10%;
}

.commitment-image img {
  width: 100%;
  max-width: 100%;
}

.commitment-text {
  text-align: left;
}

/* ============================= */
/*          Responsive Design */
/* ============================= */
@media (max-width: 768px) {
  .founder-section,
  .company-history,
  .commitment-section {
    flex-direction: column;
  }

  .founder-text,
  .history-text,
  .commitment-text,
  .expansion-strategy {
    text-align: left;
  }

  /* ============================= */
/*          Responsive Design */
/* ============================= */
@media (max-width: 1024px) {
  .about-intro {
    height: auto;
    padding: 0 5%;
    padding-top: 120px;
    padding-bottom: 40px;
  }

  .about-intro-container {
    flex-direction: column;
    text-align: center;
  }

  .about-intro-text {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }

  .about-intro-map {
    width: 100%;
  }

  .about-intro-map img {
    max-width: 350px;
  }
}
}


/* ============================= */
/*       Footer Estilos          */
/* ============================= */

.footer {
  background-color: #002B45;
  color: #fff;
  padding: 40px 5%;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* ============================= */
/*       Menú de Navegación      */
/* ============================= */

.footer-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-nav ul li {
  display: inline;
}

.footer-nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-nav ul li a:hover {
  color: #1da43b;
}

/* ============================= */
/*      Información de Contacto  */
/* ============================= */

.footer-contact h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-contact p {
  font-size: 14px;
  margin: 5px 0;
}

.footer-contact a {
  color: #1da43b;
  text-decoration: none;
  font-weight: bold;
}

.footer-contact a:hover {
  text-decoration: underline;
}

/* ============================= */
/*       Redes Sociales          */
/* ============================= */

.footer-social h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icons a {
  font-size: 20px;
  color: #fff;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #1da43b;
}

/* ============================= */
/*       Copyright & Créditos    */
/* ============================= */

.footer-bottom {
  font-size: 14px;
  opacity: 0.8;
}

.footer-bottom a {
  color: #1da43b;
  text-decoration: none;
  font-weight: bold;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

/* ============================= */
/*        Responsive Design      */
/* ============================= */

@media (max-width: 768px) {
  .footer-container {
      text-align: center;
  }

  .footer-nav ul {
      flex-direction: column;
      gap: 10px;
  }

  .social-icons {
      gap: 10px;
  }
}

@media (max-width: 480px) {
  .footer {
      padding: 30px 5%;
  }

  .footer-contact p,
  .footer-bottom p {
      font-size: 13px;
  }

  .social-icons a {
      font-size: 18px;
  }
}


/* PRODUCTS SCREEN */

/* PRODUCTS SCREEN */

/* General */
.pscreen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  padding: 80px 40px 20px; /* Espacio para navbar */
  max-width: 100%;
  box-sizing: border-box;
}

/* Barra de Búsqueda */
.search-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 24px;
}

.search-input {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
  text-align: left;
}

.search-input:focus {
  border-color: #007bff;
}

/* Grid - Mantener Tamaño Fijo */
.pscreen-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* ✅ Todas las tarjetas tienen el mismo ancho */
  column-gap: 32px;
  row-gap: 40px;
  max-width: 1200px; /* ✅ Mantiene el grid dentro de la estructura */
  width: 100%;
  padding: 10px;
  margin: auto; /* ✅ Centra el grid */
}

/* Tarjeta del Producto */
.pscreen-card {
  background-color: #fff;
  width: 280px; /* ✅ Todas las tarjetas tienen el mismo ancho */
  height: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.pscreen-card.lazy-loaded {
  opacity: 1;
  transform: translateY(0);
}

/* Imagen */
.pscreen-img {
  width: 100%;
  height: 240px;
  object-fit: scale-down;
  object-position: center;
  border-radius: 8px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.pscreen-img.lazy-loaded {
  opacity: 1;
}

/* Detalles */
.pscreen-details {
  margin-top: 12px;
  text-align: left;
  color: #232b5e;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}

/* Estrellas */
.pscreen-stars {
  color: #ffd001;
  font-size: 18px;
  margin-top: 5px;
  text-align: left;
}

/* Presentaciones */
.pscreen-sizes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
}

.pscreen-sizes div {
  margin: 5px;
  padding: 6px 12px;
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

/* Lazy Load Trigger */
.lazy-load-trigger {
  height: 10px;
  width: 100%;
  margin: 20px 0;
}

/* ============================= */
/*        Responsive Design      */
/* ============================= */

/* Tablets */
@media (max-width: 1024px) {
  .pscreen-container {
    padding-top: 100px;
  }

  .pscreen-grid {
    width: 90%;
    column-gap: 24px;
  }
}

/* Móviles */
@media (max-width: 768px) {
  .pscreen-container {
    padding-top: 120px;
  }

  .pscreen-grid {
    width: 95%;
    column-gap: 16px;
    row-gap: 20px;
  }

  .pscreen-card {
    width: 100%; /* ✅ Se mantiene el mismo ancho en móviles */
    max-width: 280px;
    padding: 12px;
    margin: auto; /* ✅ Asegura que todas las tarjetas estén alineadas */
  }

  .pscreen-img {
    height: 200px;
  }

  .search-container {
    max-width: 90%;
  }

  .search-input {
    font-size: 14px;
    padding: 10px 15px;
  }
}


/* General Layout */
/* Contenedor General */
.pdetail-container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 100px 20px 20px; /* ✅ Se asegura que haya espacio para el navbar */
  background-color: transparent;
  text-align: center;
  box-sizing: border-box;
  overflow-x: hidden; /* ✅ Evita desbordamiento horizontal */
}

/* Sección Principal */
.pdetail-main {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 100%;
}

/* Cabecera del Producto */
.pdetail-header {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
}

/* Imagen del Producto */
.pdetail-img-wrapper {
  flex: 1;
  text-align: center;
  max-width: 50%;
}

.pdetail-image {
  width: 100%;
  max-width: 350px;
  height: auto;
  object-fit: scale-down;
  border-radius: 10px;
}

/* Información del Producto */
.pdetail-info {
  flex: 1;
  text-align: left;
  max-width: 50%;
}

.pdetail-title {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 10px;
}

.pdetail-description {
  color: #16a085;
  font-size: 18px;
}

/* Secciones del Producto */
.pdetail-section {
  text-align: left;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 100%;
}

/* Composición y Presentaciones */
.pdetail-composition,
.pdetail-presentations {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pdetail-composition-item,
.pdetail-presentation-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #ecf0f1;
  border-radius: 5px;
  max-width: 100%;
}

/* Productos Relacionados */
.pdetail-related {
  text-align: center;
  margin-top: 50px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
}

/* Grid de productos relacionados */
.pdetail-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  justify-items: center;
  background-color: #fff;
  max-width: 100%;
}

/* Tarjetas de productos */
.pdetail-grid-item {
  background-color: #ffffff;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  width: 100%;
  max-width: 250px;
}

.pdetail-grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Imágenes de productos con scale-down */
.pdetail-grid-image {
  width: 100%;
  height: 200px;
  object-fit: scale-down;
  object-position: center;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* ============================= */
/*        Responsive Design      */
/* ============================= */

/* Tablets */
@media (max-width: 1024px) {
  .pdetail-container {
    padding: 120px 15px 40px; /* ✅ Espacio para el navbar fijo */
  }

  .pdetail-header {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .pdetail-img-wrapper,
  .pdetail-info {
    max-width: 100%;
  }

  .pdetail-image {
    max-width: 80%;
  }

  .pdetail-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Móviles */
@media (max-width: 768px) {
  .pdetail-container {
    padding-top: 150px; /* ✅ Mayor espacio para navbar fijo */
  }

  .pdetail-grid {
    grid-template-columns: 1fr;
  }

  .pdetail-grid-image {
    height: 180px;
  }

  .pdetail-header {
    flex-direction: column;
  }

  .pdetail-img-wrapper,
  .pdetail-info {
    max-width: 100%;
  }

  .pdetail-image {
    max-width: 90%;
  }
}

/* Botón flotante de descarga - Ficha Técnica */
.pdetail-download-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 14px 24px;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 1000;
}

/* Efecto hover */
.pdetail-download-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Responsividad para tablets y móviles */
@media (max-width: 768px) {
  .pdetail-download-button {
    bottom: 15px;
    right: 15px;
    padding: 12px 20px;
    font-size: 14px;
  }
}

/* Contenedor principal */
.distribuidores-container {
  width: 100%;
  max-width: 1200px; /* ✅ Mantiene la estructura dentro del sitio */
  margin: auto;
  padding: 60px 40px 20px;
  background-color: #f8f9fa;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box; /* ✅ Evita que padding y margin afecten el tamaño */
}

/* Título Principal */
.title {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 20px;
  text-transform: uppercase;
}

/* Sección por Estado */
.estado-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  max-width: 100%; /* ✅ Evita que se salga de la estructura */
}

/* Título de Estado */
.estado-title {
  font-size: 22px;
  color: #2980b9;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 2px solid #16a085;
  padding-bottom: 5px;
}

/* Lista de distribuidores */
.distribuidores-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.distribuidor-item {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #2c3e50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s ease;
  max-width: 100%; /* ✅ Evita que los elementos se salgan del contenedor */
}

.distribuidor-item:hover {
  background-color: #eafaf1;
}

/* Teléfono con estilo especial */
.distribuidor-item span.telefono {
  color: #16a085;
  font-weight: bold;
  font-size: 16px;
}

/* ============================= */
/*        Responsive Design      */
/* ============================= */

/* Tablets */
@media (max-width: 1024px) {
  .distribuidores-container {
    padding: 100px 30px 50px; /* ✅ Agrega espacio para navbar fijo */
  }
  .estado-section {
    padding: 15px;
  }
  .estado-title {
    font-size: 20px;
  }
}

/* Móviles */
@media (max-width: 768px) {
  .distribuidores-container {
    padding: 120px 15px 40px; /* ✅ Mayor padding-top para el navbar fijo */
  }
  .title {
    font-size: 24px;
  }
  .estado-title {
    font-size: 18px;
  }
  .distribuidor-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    max-width: 100%;
  }
  .distribuidor-item span.telefono {
    margin-top: 5px;
  }
}
